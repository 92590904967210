@use './variables.scss' as variables;

@function mq-width($size: 'md', $direction: 'min') {
    $sizes: map-get(variables.$mq-sizes-directions, $direction);
    @return map-get($sizes, $size);
}

@mixin mq($size: 'md', $direction: 'min') {
    @media (#{$direction}-width: mq-width($size, $direction)) {
        @content;
    }
}
