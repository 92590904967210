@use 'sass:math';
// Creds to https://github.com/kristoferjoseph/flexboxgrid
// No gutters
// No text alignment with justified container classes (center-xs, for example)

.row {
    @include row();

    @for $i from 1 through length($breakpoint-up-prefixes) {
        $thisPrefix: nth($breakpoint-up-prefixes, $i);

        @if $thisPrefix == 'xs' {
            margin: 0 -#{math.div(map-get($gutters, $thisPrefix), 2)};
        } @else if $thisPrefix == 'sm' {
            @media #{$breakpoint-sm-up} {
                margin: 0 -#{math.div(map-get($gutters, $thisPrefix), 2)};
            }
        } @else if $thisPrefix == 'md' {
            @media #{$breakpoint-md-up} {
                margin: 0 -#{math.div(map-get($gutters, $thisPrefix), 2)};
            }
        } @else if $thisPrefix == 'lg' {
            @media #{$breakpoint-lg-up} {
                margin: 0 -#{math.div(map-get($gutters, $thisPrefix), 2)};
            }
        } @else if $thisPrefix == 'xl' {
            @media #{$breakpoint-xl-up} {
                margin: 0 -#{math.div(map-get($gutters, $thisPrefix), 2)};
            }
        }

        &.row-grid {
            @if $thisPrefix == 'xs' {
                margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
            } @else if $thisPrefix == 'sm' {
                @media #{$breakpoint-sm-up} {
                    margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
                }
            } @else if $thisPrefix == 'md' {
                @media #{$breakpoint-md-up} {
                    margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
                }
            } @else if $thisPrefix == 'lg' {
                @media #{$breakpoint-lg-up} {
                    margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
                }
            } @else if $thisPrefix == 'xl' {
                @media #{$breakpoint-xl-up} {
                    margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
                }
            }
        }
    }
}

// .row.reverse {
//     @include row-reverse();
// }

// .row.natural-height {
//     @include row-natural-height();
// }

// .row.flex-column {
//     @include row-flex-column();
// }

.row > [class*='col-'],
.row > [class^='col-'] {
    @for $i from 1 through length($breakpoint-up-prefixes) {
        $thisPrefix: nth($breakpoint-up-prefixes, $i);

        @if $thisPrefix == 'xs' {
            padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
        } @else if $thisPrefix == 'sm' {
            @media #{$breakpoint-sm-up} {
                padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'md' {
            @media #{$breakpoint-md-up} {
                padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'lg' {
            @media #{$breakpoint-lg-up} {
                padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'xl' {
            @media #{$breakpoint-xl-up} {
                padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
            }
        }
    }
}

.row.row-grid > [class*='col-'],
.row.row-grid > [class^='col-'] {
    @for $i from 1 through length($breakpoint-up-prefixes) {
        $thisPrefix: nth($breakpoint-up-prefixes, $i);

        @if $thisPrefix == 'xs' {
            padding: math.div(map-get($gutters, $thisPrefix), 2);
        } @else if $thisPrefix == 'sm' {
            @media #{$breakpoint-sm-up} {
                padding: math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'md' {
            @media #{$breakpoint-md-up} {
                padding: math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'lg' {
            @media #{$breakpoint-lg-up} {
                padding: math.div(map-get($gutters, $thisPrefix), 2);
            }
        } @else if $thisPrefix == 'xl' {
            @media #{$breakpoint-xl-up} {
                padding: math.div(map-get($gutters, $thisPrefix), 2);
            }
        }
    }
}

// .col.reverse {
//     @include col-reverse();
// }

.first {
    order: -1;
}

.last {
    order: 1;
}

.align-start {
    align-self: flex-start;
}

.align-end {
    align-self: flex-end;
}

.align-center {
    align-self: center;
}

.align-baseline {
    align-self: baseline;
}

.align-stretch {
    align-self: stretch;
}

// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
@mixin col-factory($thisPrefix) {
    // .row {
    //     margin: -#{math.div(map-get($gutters, $thisPrefix), 2)};
    // }
    .col-#{$thisPrefix} {
        box-sizing: border-box;
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }
    @for $i from 1 through $grid-columns {
        .col-#{$thisPrefix}-#{$i} {
            box-sizing: border-box;
            flex-basis: 100% / $grid-columns * $i;
            max-width: 100% / $grid-columns * $i;
        }
        .col-#{$thisPrefix}-offset-#{$i} {
            margin-left: 100% / $grid-columns * $i;
        }
    }
    .row.start-#{$thisPrefix} {
        justify-content: flex-start;
    }
    .row.center-#{$thisPrefix} {
        justify-content: center;
    }
    .row.end-#{$thisPrefix} {
        justify-content: flex-end;
    }
    .row.top-#{$thisPrefix} {
        align-items: flex-start;
    }
    .row.middle-#{$thisPrefix} {
        align-items: center;
    }
    .row.bottom-#{$thisPrefix} {
        align-items: flex-end;
    }
    .row.around-#{$thisPrefix} {
        justify-content: space-around;
    }
    .row.between-#{$thisPrefix} {
        justify-content: space-between;
    }
    .first-#{$thisPrefix} {
        order: -1;
    }
    .last-#{$thisPrefix} {
        order: 1;
    }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through length($breakpoint-up-prefixes) {
    $thisPrefix: nth($breakpoint-up-prefixes, $i);

    @if $thisPrefix == 'xs' {
        @include col-factory($thisPrefix);
    } @else if $thisPrefix == 'sm' {
        @media #{$breakpoint-sm-up} {
            @include col-factory($thisPrefix);
        }
    } @else if $thisPrefix == 'md' {
        @media #{$breakpoint-md-up} {
            @include col-factory($thisPrefix);
        }
    } @else if $thisPrefix == 'lg' {
        @media #{$breakpoint-lg-up} {
            @include col-factory($thisPrefix);
        }
    } @else if $thisPrefix == 'xl' {
        @media #{$breakpoint-xl-up} {
            @include col-factory($thisPrefix);
        }
    }
}

// .col-gutter-lr {
//     @for $i from 1 through length($breakpoint-up-prefixes) {
//         $thisPrefix: nth($breakpoint-up-prefixes, $i);

//         @if $thisPrefix == 'xs' {
//             padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
//         } @else if $thisPrefix == 'sm' {
//             @media #{$breakpoint-sm-up} {
//                 padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
//             }
//         } @else if $thisPrefix == 'md' {
//             @media #{$breakpoint-md-up} {
//                 padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
//             }
//         } @else if $thisPrefix == 'lg' {
//             @media #{$breakpoint-lg-up} {
//                 padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
//             }
//         } @else if $thisPrefix == 'xl' {
//             @media #{$breakpoint-xl-up} {
//                 padding: 0 math.div(map-get($gutters, $thisPrefix), 2);
//             }
//         }
//     }
// }

.col-no-gutter {
    padding: 0;
}
