@use '@angular/material' as mat;
@use 'common/src/lib/style/material/variables' as theme;
@use 'common/src/lib/style/variables' as var;
@use 'common/src/lib/style/tools' as tools;

.theme-vinborsen {
  --mdc-shape-medium: 16px;

  button.mdc-menu-item {
    display: inline-flex;
    align-items: center;

    .ui-flag-icon {
      margin-right: 16px;
    }

    &.--active {
      font-weight: bold;
    }
  }

  .mdc-tooltip {
    background-color: rgba(#000, 0.85);
    font-size: 14px;
    text-align: center;
  }

  .mdc-form-field {
    width: 100%;

    &[stand-alone] .mdc-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .mdc-form-field-appearance-outline {
    font-size: 16px;
    line-height: 18px;

    .mdc-hint {
      font-size: 12px;
    }

    .mdc-form-field-outline {
      color: #ddd;
      background-color: #fff;
      border-radius: 6px;
    }

    .mdc-form-field-outline-thick {
      color: #ccc;
    }

    .mdc-form-field-infix {
      width: auto;
    }

    &.mdc-form-field-disabled {
      .mdc-form-field-outline {
        background-color: var.$color-gray--lighter;
        color: var.$color-gray--light;
      }

      .mdc-form-field-label {
        color: var.$color-gray--light;
      }
    }
  }

  .mdc-list-base[dense] .mdc-list-item {
    font-size: 16px;
  }

  .mat-checkbox {
    .mdc-checkbox-inner-container {
      background-color: #fff;
    }

    &[wrap] .mdc-checkbox-label {
      white-space: normal;
      line-height: 18px;
    }

    &.ng-touched.ng-invalid {
      .mdc-checkbox-frame {
        border-color: var.$color-error;
      }
    }
  }

  .mdc-icon {
    &[size='huge'] {
      height: 60px;
      width: 60px;
      min-width: 60px;
      font-size: 60px;
    }

    &[size='large'] {
      height: 30px;
      width: 30px;
      min-width: 30px;
      font-size: 30px;

      @include tools.mq(md) {
        height: 40px;
        width: 40px;
        min-width: 40px;
        font-size: 40px;
      }
    }

    &[size='medium'] {
      height: 30px;
      width: 30px;
      min-width: 30px;
      font-size: 30px;
    }
  }

  .mdc-button {
    &[expanded] {
      width: 100%;
      text-align: center;
    }

    &.loading {
      color: transparent;

      .mat-mdc-progress-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @include tools.mq(sm, 'max') {
      &[expanded='sm'] {
        width: 100%;
        text-align: center;
      }
    }

    &[size='small'] {
      font-size: 14px;
      line-height: 14px;
    }

    &[size='large'] {
      padding-left: 32px;
      padding-right: 32px;
      height: 58px;
      min-width: 80px;
      border-radius: 10px;

      font-size: mat.m2-font-size(theme.$config, 'body-1');

      .mdc-icon {
        line-height: 24px;
      }
    }

    &[size='huge'] {
      font-size: 24px;
      height: 60px;
      padding-left: 32px;
      padding-right: 32px;
      min-width: 104px;
    }
  }

  .mdc-button--unelevated {
    &.mat-accent {
      color: #fff;
    }
  }

  .mdc-dialog-container {
    padding: 0;
    border-radius: 16px;
  }

  .mat-dialog-header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    gap: 8px;
    padding: 20px;

    h2,
    h3,
    h4 {
      flex: 1 1 auto;
      margin: 0;
      padding: 0;
      overflow: hidden;
      word-wrap: break-word;
    }

    .mdc-icon-button {
      flex: 0 0 auto;
      margin: -10px -8px 0 0;
    }
  }

  .mat-mdc-dialog-container {
    .mdc-dialog__title {
      display: inline-flex;
      @include mat.m2-typography-level(theme.$config, subtitle-1);

      .mdc-icon {
        margin-right: 10px;
      }
    }

    .mdc-dialog__content {
      padding: 0 20px;
      margin: 0;

      > :first-child {
        margin-top: 0;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .mdc-dialog__actions {
      padding: 20px;
      margin: 0;
      justify-content: flex-end;
      min-height: auto;
    }
  }

  .mdc-option {
    position: relative;

    &[label]:after {
      content: attr(label);
      position: absolute;
      font-size: 10px;
      line-height: 10px;
      padding: 2px 4px;
      right: 8px;
      top: 50%;
      margin-top: -7px;
      background-color: #999;
      color: #fff;
      border-radius: 3px;
    }
  }

  .mat-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        padding: 16px 8px;
        font-size: 20px;
        line-height: 24px;
      }

      .mat-content {
        align-items: center;
      }

      .mat-expansion-panel-body {
        padding: 16px 8px 24px;
        font-size: 17px;
        line-height: 24px;
      }

      .mat-expansion-panel-content {
        padding: 0;
      }

      @include tools.mq(md) {
        .mat-expansion-panel-header {
          padding: 16px 8px;
          font-size: 24px;
          line-height: 28px;
        }

        .mat-expansion-panel-body {
          padding: 16px 8px 24px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }

    &[bordered='true'] {
      .mat-expansion-panel {
        &:not(:first-child) {
          border-top: 1px solid var.$color-border;
        }

        &.mat-expanded {
          border-top: none;
          border-top: 3px solid var.$color-border;

          .mat-expansion-panel-header {
            border-radius: var.$border-radius;
            font-weight: 500;
          }
        }
      }
    }

    &[mode='tight'] {
      .mat-expansion-panel {
        .mat-expansion-panel-header {
          padding: 0;
        }

        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }
}
