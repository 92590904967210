.product-icons-icon {
    height: 60px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &[size='medium'] {
        height: 50px;
    }
    &[size='small'] {
        height: 46px;

        .label {
            font-size: 10px;
            line-height: 12px;
        }
    }
}
