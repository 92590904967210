@use 'common/src/lib/style/variables' as vb;
@use 'common/src/lib/style/material' as theme;

// @use 'normalize.css' as *;

// UI Lib
@use 'common/src/lib/style' as *;

// Utilities
@use 'common/src/lib/style/utilities' as utils;

// Tools
@use 'common/src/lib/style/tools' as tools;

// Core
@use 'core/layout' as *;
@use 'core/typography' as *;
@use 'core/material-overrides' as *;

// Components
@use 'components/header' as *;
@use 'components/icons' as *;

/**
 * Grid
*/
@use './core/grid-config.scss' as gridconfig;
@use 'grid/src/lib' as grid with (
    $gutters: gridconfig.$gutters,
    $xs-max: map-get(vb.$mq-sizes-max, 'xs'),
    $sm-max: map-get(vb.$mq-sizes-max, 'sm'),
    $md-max: map-get(vb.$mq-sizes-max, 'md'),
    $lg-max: map-get(vb.$mq-sizes-max, 'lg')
);

// Import CSS Flags
@use './core/flag-icons-config.scss' as *;
@use 'flag-icons/sass/flag-icons' with (
    $flag-icons-path: '/assets/flags'
);

// =============================================================================

@include utils.create-utility-spacing('margin');
@include utils.create-utility-spacing('padding');
@include utils.create-utility-responsive();

.product-card-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  &[max-size='4'] .product-card-grid__item {
    @include tools.mq(md) {
      max-width: 25%;
      flex-basis: 25%;

      &.--featured {
        max-width: 50%;
        flex-basis: 50%;
      }
    }
  }
}

.product-card-grid__item {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 8px;
  box-sizing: border-box;

  .ui-product-card {
    height: 100%;
  }

  &.--featured {
    max-width: 100%;
    flex-basis: 100%;
  }

  @include tools.mq(sm) {
    max-width: calc(100% / 3);
    flex-basis: calc(100% / 3);

    &.--featured {
      max-width: calc((100% / 3) * 2);
      flex-basis: calc((100% / 3) * 2);
    }
  }
}

// =============================================================================

.list-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
}

.ui-byline {
  display: flex;
  align-items: center;
  color: vb.$color-primary-text;
}

.ui-byline-avatar {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.ui-byline-body {
  letter-spacing: 0.06em;
}

.ui-byline__name {
  text-transform: uppercase;
  display: block;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
}

.ui-byline__date {
  display: block;
  opacity: 0.4;
  font-size: 14px;
  line-height: 18px;
}

.info-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;

  @include tools.mq(sm) {
    .info-grid-tile:nth-child(odd) {
      padding-right: 20px;
    }
    .info-grid-tile:nth-child(even) {
      padding-left: 20px;
    }
  }
}

.info-grid-tile {
  width: 100%;
  flex-basis: 100%;
  box-sizing: border-box;
  margin: 16px 0;

  @include tools.mq(sm) {
    flex: 1 1 50%;
    width: 50%;
    // margin-top: 16px;
    // margin-bottom: 16px;
  }
}

.info-box {
  position: relative;

  h3 {
    margin: 0 0 8px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 8px;
  }
}

.info-box + .info-box {
  margin-top: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(#fff, 0.8);
  z-index: 99;

  &.--opaque {
    background-color: #fff;
  }

  &.--transparent {
    background-color: transparent;
  }

  &.--fixed {
    position: fixed;
  }

  &.--top {
    align-items: flex-start;
  }
}

.placeholder {
  position: relative;
  background-color: #f8f8f8;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: placeholderShimmer 2s ease infinite forwards;
    background: linear-gradient(to right, transparent 0%, #fff 20%, transparent 40%);
  }
}

.placeholder-circle {
  @extend .placeholder;

  height: 80px;
  width: 80px;
  border-radius: 100%;
}

.placeholder-box {
  @extend .placeholder;

  width: 100%;
  height: 200px;

  border-radius: 8px;

  &.as-h1 {
    @extend h1;
    height: 41px;
  }

  &.as-h2 {
    @extend h2;
    height: 31px;
  }

  &.as-h3 {
    @extend h3;
    height: 31px;
  }

  &.as-p {
    @extend p;
    height: 24px;
  }

  @include tools.mq(sm) {
    &.as-h1 {
      height: 58px;
    }
    &.as-h2 {
      height: 41px;
    }

    &.as-p {
      height: 28px;
    }
  }
}

@keyframes placeholderShimmer {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(100%);
  }
}

.note {
  display: flex;

  &.--centered {
    align-items: center;
  }

  &.--muted {
    opacity: 0.4;
  }

  &[background] {
    padding: 16px;
    background-color: vb.$color-gray--light;
  }

  &[background='lighter'] {
    background-color: vb.$color-gray--lighter;
  }

  &[background='green'] {
    background-color: vb.$color-bg-green;
  }

  .mat-icon {
    flex: 0 0 auto;
    margin-right: 8px;
    font-size: 16px;
    line-height: 17px;
    width: 16px;
    height: 16px;
    color: vb.$color-black;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: vb.$color-gray;
  }
}

.teaser {
  background-color: vb.$color-bg-green;
  padding: 4px;

  background-image: url('/assets/patterns/generic-1.svg');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: auto 40%;

  @include tools.mq(md) {
    padding: 8px;
    background-size: auto 75%;
  }

  &.--vertical {
    .teaser-content {
      flex-direction: column;
      text-align: center;
    }
  }
}

.teaser-content {
  display: flex;
  flex-direction: column;
  border: 1px dotted vb.$color-gray--light;
  padding: 16px;

  h1 {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    flex: 1 1 auto;
    margin: 0 0 16px;
  }

  @include tools.mq(md) {
    flex-direction: row;
    padding: 24px;
    align-items: center;

    h1 {
      text-align: left;
      flex: 1 1 auto;
      margin: 0;
    }

    a.mat-button {
      flex: 0 0 auto;
      font-size: 20px;
      margin: 0 16px;
    }
  }
}
