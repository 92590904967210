@use './variables' as var;

$spacing-base: 8 !default;
$spacing-directions: ('', 'x', 'y', 't', 'r', 'b', 'l') !default;
$spacing-sizes: ('n', 'xs', 's', 'm', 'l', 'xl', 'xxl') !default;

// u-marginT-m
@mixin box-model($direction, $type, $value, $size: '') {
    .u-#{$type + to-upper-case($direction)}-#{$size} {
        @if $direction == '' {
            #{$type}: $value !important;
        }

        @if $direction == 't' or $direction == 'y' {
            #{$type}-top: $value !important;
        }

        @if $direction == 'r' or $direction == 'x' {
            #{$type}-right: $value !important;
        }

        @if $direction == 'b' or $direction == 'y' {
            #{$type}-bottom: $value !important;
        }

        @if $direction == 'l' or $direction == 'x' {
            #{$type}-left: $value !important;
        }
    }
}

@mixin create-utility-spacing($type: 'margin') {
    @each $direction in $spacing-directions {
        @for $count from 1 through length($spacing-sizes) {
            $index: $count - 1;
            $value: $spacing-base * $index;

            @include box-model($direction, $type, $value + px, nth($spacing-sizes, $count));
        }
    }
}

/**
Responsive Utility Classes

Examples:
.u-sm-hide // Hide for small only
.u-sm-up-hide // Hide for small and up
.u-md-down-hide // Hide for medium and down
*/
@mixin create-utility-responsive() {
    @for $i from 1 through length(var.$mq-sizes) {
        $key: nth(var.$mq-sizes-names, $i);
        $min: nth(var.$mq-sizes-values-min, $i);
        $max: nth(var.$mq-sizes-values-max, $i);

        @media (min-width: $min) {
            .u-#{$key}-up-hide {
                display: none !important;
            }
        }

        @media (max-width: $max) {
            .u-#{$key}-down-hide {
                display: none !important;
            }
        }

        @media (min-width: $min) and (max-width: $max) {
            .u-#{$key}-hide {
                display: none !important;
            }
        }
    }
}
