@use 'common/src/lib/style/variables' as var;
@use 'common/src/lib/style/tools' as tools;

app-home {
    display: block;
    overflow-x: hidden;
}

.app-content {
    min-height: calc(100vh - #{var.$theme-header-height--mobile + 56px + 20px});
    padding-top: var.$theme-header-height--mobile;

    @include tools.mq(sm) {
        min-height: calc(100vh - #{var.$theme-header-height + 56px + 20px});
        padding-top: var.$theme-header-height;
    }
}

.app-footer {
    @include tools.mq(md) {
        .app-footer-body {
            flex-direction: row;
            align-items: end;
            text-align: left;
            font-size: 16px;
        }
        .app-footer__logo {
            width: 200px;
            margin-bottom: 0;
        }
        .app-footer__right {
            flex: 0 0 auto;
            margin-top: 16px;
        }
        .app-footer__left {
            padding-left: 80px;
            max-width: 600px;
        }
    }
}
.app-footer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.app-footer__logo {
    width: 200px;
    margin-bottom: 20px;
}
.app-footer__right,
.app-footer__left {
    flex: 1 1 auto;

    small {
        font-size: 10px;
        text-transform: uppercase;
    }
    p {
        margin: 16px 0;
        color: rgba(var.$color-primary-text, 0.7);

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.app-section {
    display: block;
    margin: 8px 0;
    padding: 20px 0;
    background-color: #fff;

    > p {
        color: var.$color-gray;
    }
    > h2 + p {
        margin-top: -20px;
    }

    &.--transparent {
        background-color: transparent;
    }

    &.--first {
        margin-top: 0;
    }
    &.--last {
        margin-bottom: 0;
    }
    &.--tight {
        margin-top: 0;
        margin-bottom: 0;
    }

    &.--primary {
        background-color: var.$color-bg-green;
    }

    &.--decorated {
        background-image: url('/assets/patterns/generic-1.svg');
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: auto 40%;
    }

    &.--centered {
        text-align: center;
    }
    &.--centered-flex {
        display: flex;
        align-items: center;
    }

    &.--p-large {
        padding: 40px 0;
    }
    &.--p-small {
        padding: 16px 0;
    }
    &.--p-none {
        padding: 0;
    }

    @include tools.mq(md) {
        margin: 16px 0;
        padding: 56px 0;

        &.--p-large {
            padding: 80px 0;
        }

        &.--p-small {
            padding: 24px 0;
        }
    }
}

.app-container {
    margin: 0 auto;
    max-width: 1100px;
    padding: 0 16px;
    box-sizing: content-box;
    position: relative;

    @include tools.mq(sm) {
        padding: 0 24px;
    }
    @include tools.mq(md) {
        padding: 0 40px;
    }

    &.--full,
    &[size='full'] {
        max-width: 100%;
        padding: 0;
    }
    &.--large,
    &[size='large'] {
        max-width: 1240px;
    }
    &.--small,
    &[size='small'] {
        max-width: 800px;
    }
    &.--x-small {
        max-width: 600px;
    }

    &.--secondary {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: var.$color-bg-green;
    }

    &.app-container--sm-full {
        @include tools.mq(sm, 'max') {
            padding: 0;
        }
    }

    &.--centered {
        text-align: center;
    }
    &.--centered-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.app-heading {
    padding: 80px 0;

    &.--primary {
        background-color: var.$color-bg-green;
    }

    &.--centered {
        text-align: center;
    }
}

.app-headline {
    display: flex;
    align-items: center;
    gap: 16px;

    h1,
    h2,
    h3,
    h4 {
        flex: 1 1 auto;
    }

    a,
    .mdc-button {
        flex: 0 0 auto;
    }
}
