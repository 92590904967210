@use '@angular/material' as mat;
@use 'common/src/lib/style/material/variables' as theme;
@use 'common/src/lib/style/variables' as var;
@use 'common/src/lib/style/tools' as tools;

body {
  background-color: var.$color-bg-body;
  margin: 0;
  line-height: 1.3;
  font-family: var.$font-family-base;
  font-weight: 400;
  font-style: normal;
  text-rendering: geometricPrecision;
  scroll-behavior: smooth;
}

.text-muted {
  color: rgba(var.$color-primary-text, 0.3);
}

h1,
h2,
h3,
h4,
h5 {
  color: var.$color-primary-text;

  > a {
    color: inherit;
  }

  &.--muted {
    color: map_get(theme.$mat-light-theme-foreground, secondary-text);
  }
}

h1,
.h1 {
  font-size: 32px;
  line-height: 41px;
  font-weight: 400;
  color: var.$color-primary-text;
  margin: 0.67em 0;

  @include tools.mq(sm) {
    font-size: 52px;
    line-height: 58px;
  }
}

h2,
.h2 {
  font-size: 24px;
  line-height: 31px;
  font-weight: 400;
  color: var.$color-primary-text;
  margin: 0.67em 0;

  &:not([size='small']) {
    @include tools.mq(sm) {
      font-size: 32px;
      line-height: 41px;
    }
  }
}

h3,
.h3 {
  font-size: 24px;
  line-height: 31px;
  font-weight: 400;
  margin: 1em 0;

  &,
  > a {
    color: var.$color-primary-text;
  }

  &.vb-caption {
    margin: 3em 0 1em;
  }
}

h4,
.h4 {
  font-size: 17px;
  line-height: 21px;
  font-weight: 500;
  margin: 1em 0;

  &.vb-caption {
    margin: 3em 0 1em;
  }
}

.vb-caption {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  color: var.$color-primary-text;

  &.--muted {
    color: map_get(theme.$mat-light-theme-foreground, secondary-text);
  }
}

p {
  color: var.$color-primary-text;

  &.--muted {
    color: map_get(theme.$mat-light-theme-foreground, secondary-text);
  }

  &[size='mini'] {
    font-size: 11px;
    line-height: 14px;
  }

  &[size='xsmall'] {
    font-size: 14px;
    line-height: 18px;
  }

  &[size='small'],
  small {
    font-size: 17px;
    line-height: 21px;
  }

  &[size='large'] {
    font-size: 24px;
    line-height: 31px;
  }

  &[size='huge'] {
    font-size: 32px;
    line-height: 41px;
  }
}

a {
  color: var.$color-accent-text;
  text-decoration: none;
  cursor: pointer;

  &.--underlined {
    text-decoration: underline;
  }
}

p,
ul {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;

  @include tools.mq(sm) {
    font-size: 20px;
    line-height: 28px;
  }

  &.--large {
    @include tools.mq(sm) {
      font-size: 24px;
      line-height: 31px;
    }
  }

  &.--small {
    font-size: 14px;
    line-height: 18px;
  }

  &.--muted {
    color: map_get(theme.$mat-light-theme-foreground, secondary-text);
  }
}

p.vb-icon-paragraph {
  display: inline-flex;
  align-items: center;
  min-height: 24px;

  .mat-icon {
    margin-right: 5px;
  }

  > span {
    flex: 1 1 auto;
  }

  @include tools.mq(md) {
    line-height: 42px;
  }
}

ul.vb-icon-list {
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    line-height: 32px;
  }

  .mat-icon {
    margin-right: 5px;
    line-height: 24px;
  }

  @include tools.mq(md) {
    li {
      line-height: 42px;
    }
  }
}

.vb-separator {
  &:after {
    content: '·';
    padding: 0 8px;
    font-size: mat.m2-font-size(theme.$config, caption);
    font-weight: 400;
    color: map_get(theme.$mat-light-theme-foreground, hint-text);
    line-height: 15px;
    vertical-align: middle;
  }
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rich-text {
  > h1,
  > h2,
  > h3,
  > h4 {
    font-family: var.$font-family-alt;
    font-weight: 400;
    margin-top: 2em;
    margin-bottom: 0.5em;
    text-transform: none;
    color: var.$color-primary-text;
  }

  > h2 {
    font-size: 32px;
  }

  > h3 {
    font-size: 26px;
  }

  > h4 {
    font-size: 20px;
    margin-bottom: 0;
  }

  > p {
    font-size: 20px;
    line-height: 25px;
    margin-top: 0.5em;
    margin-bottom: 1.2em;
  }

  > [rich-text-block-product] {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .ui-image {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  > p,
  > h1,
  > h2,
  > h3,
  > h4 {
    a {
      color: var.$color-primary-text;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }

  [rich-text-block-video] {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  [rich-text-block-product] {
    margin: 2em -2em;
  }
}
