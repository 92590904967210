////////////////////////////////////////////////////////////////////////////////
// Colors

$color-black: #393d3b;
$color-gray: #989f9a;
$color-gray--light: #e4e4e4;
$color-gray--lighter: #f9f9f9;
$color-green: #0ac470;
$color-red: #e95d5d;
$color-orange: #f4a55d;
$color-blue: #77cdec;
$color-purple: #9e6dff;
$color-yellow: #e3da66;
$color-yellow--light: #faf7dd;

// Backgrounds
$color-bg-green: #ecf3ee;
$color-bg-blue: #f0fbff;
$color-bg-red: #ffeae1;
$color-bg-orange: #fff6e0;
$color-bg-purple: #f5f0ff;
$color-bg-yellow: #fffef0;
$color-bg-body: $color-gray--lighter;

$color-primary: $color-black;
$color-accent: $color-green;

$color-primary-text: $color-primary;
$color-accent-text: $color-accent;
$color-secondary-text: $color-gray;
$color-disabled-text: $color-secondary-text;
$color-dividers: rgba($color-gray--light, 0.8);
$color-border: $color-dividers;
$color-focused: rgba($color-primary-text, 0.12);

// Notifications
$color-success: $color-green;
$color-error: $color-red;

// Social
$color-social-facebook: #4172b8;
$color-social-twitter: #1da1f2;
$color-social-instagram: #e4405f;
$color-social-youtube: #ff0000;
$color-social-vimeo: #1ab7ea;

////////////////////////////////////////////////////////////////////////////////
// Z Index

$z-index-header: 10;

////////////////////////////////////////////////////////////////////////////////
// Font
$font-family-base: 'futura-pt', sans-serif;
$font-family-alt: 'futura-pt', sans-serif;
// $font-family-special: #{mina, serif};

////////////////////////////////////////////////////////////////////////////////
// Responsive
$mq-sizes: (
    xs: 0px,
    sm: 600px,
    md: 960px,
    lg: 1200px
);
$mq-sizes-min: $mq-sizes;
$mq-sizes-max: (
    xs: map-get($mq-sizes, sm) - 1px,
    sm: map-get($mq-sizes, md) - 1px,
    md: map-get($mq-sizes, lg) - 1px,
    lg: null
);

$mq-sizes-directions: (
    min: $mq-sizes-min,
    max: $mq-sizes-max
);

$mq-sizes-names: map-keys($mq-sizes);
$mq-sizes-values-min: map-values($mq-sizes-min);
$mq-sizes-values-max: map-values($mq-sizes-max);

////////////////////////////////////////////////////////////////////////////////
// Spacing
$spacing-base: 8;
$spacing-directions: ('', 'x', 'y', 't', 'r', 'b', 'l');
$spacing-sizes: ('n', 'xs', 's', 'm', 'l', 'xl', 'xxl');

$grid-max-columns: 12;
$grid-gutter-width: $spacing-base * 4px;

$container-gutter-width: $grid-gutter-width;

////////////////////////////////////////////////////////////////////////////////
// Misc

$border-radius: 4px;

////////////////////////////////////////////////////////////////////////////////
// Components

$theme-header-height: 64px !default;
$theme-header-height--mobile: 54px !default;
