@use 'common/src/lib/style/settings' as vb;

body.megamenu-open {
    overflow: hidden;
}

.theme-vinborsen {
    .app-header-toolbar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);
        background: #fff;
    }
}

.app-header-toolbar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 50%;
}
.app-header-toolbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.app-header-toolbar-right {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;

    .mat-button.active {
        font-weight: 500;
    }

    @include vb.mq(md) {
        gap: 8px;

        .app-header-divider {
            border-right: 1px solid vb.$color-dividers;
            height: 24px;
            margin: 0 4px;
        }
    }
}

.app-header__brand {
    flex: 0 0 auto;
    height: 24px;
    display: flex;

    img {
        height: 24px;
        max-height: 100%;
    }

    @include vb.mq(sm) {
        height: 34px;

        img {
            height: 34px;
        }
    }
}

mat-toolbar.app-header-megamenu__mobile-title {
    position: fixed;
    margin-bottom: 24px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;

    @include vb.mq(md) {
        display: none;
    }
}

app-mainmenu {
    position: relative;
    z-index: 100;

    @include vb.mq(md) {
        z-index: 99;
    }
}

.app-header-megamenu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid vb.$color-gray--light;
    overflow: auto;
    padding: vb.$theme-header-height--mobile 0;

    @include vb.mq(md) {
        border-bottom: 1px solid vb.$color-gray--light;
        box-shadow: 0 4px 30px rgba(#000, 0.04);
        top: vb.$theme-header-height;
        padding: 45px 0;
        bottom: auto;
    }
}
.app-header-megamenu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#fff, 0.3);
    overflow: hidden;
}

.app-megamenu {
    display: flex;
    flex-wrap: wrap;

    @include vb.mq(md) {
        flex-wrap: nowrap;
    }
}
.app-megamenu-item {
    flex: 1 1 50%;
    max-width: 50%;

    > .label {
        padding: 0 16px;
    }

    .mdc-list-item--with-leading-icon .mdc-list-item__start {
        margin-right: 8px;
    }

    .app-megamenu-item__footer-link {
        padding: 0 16px;
        margin: 16px 0 0;
        font-size: 16px;
    }

    &:not(.--selected) {
        display: none;
    }

    &.--tabs {
        flex: 0 0 100%;
        display: block;
        max-width: 100%;

        .mat-mdc-list-item {
            &.mdc-list-item--activated {
                background-color: vb.$color-gray--light !important;
            }
        }
    }

    @include vb.mq(sm, 'max') {
        margin-bottom: 16px;

        &.--tabs {
            border-bottom: 1px solid vb.$color-gray--light;

            > .label {
                display: none;
            }

            .mat-nav-list {
                display: flex;
                justify-content: space-between;

                .mat-list-item {
                    flex: 1 1 auto;
                    width: 80px;
                    height: 80px;

                    .mat-icon {
                        margin-right: 0;
                    }
                }
                .mat-list-item-content {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0;
                }
                .mat-list-item__label {
                    margin-top: 8px;
                    font-size: 16px;
                }
            }
        }
    }

    @include vb.mq(md) {
        flex: 1 1 auto;
        max-width: 240px;

        &.--tabs {
            flex: 0 0 180px;
            width: 180px;
            margin-right: 16px;
            border-right: 1px solid vb.$color-gray--light;
        }
    }
}
